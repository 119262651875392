<template>
  <div class="py-[10px]">
    <div class="w-full bg-white p-[10px] h-full">
      <div class="mt-[15px]">
        <payment-list/>
      </div>
      <div class="mt-[40px]">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-3">
          <div class="w-full px-[10px]">
            <div class="w-full pb-[20px]">
              <h5 class="text-[20px] font-semibold">{{ $t('git.actions') }}</h5>
            </div>
            <div class="grid grid-cols-1 gap-3">
              <div class="py-[5px]">
                <button v-b-tooltip="$t('git.invoice.paid_git_invoice.fetch_paid_invoices_from_accounting')"
                        v-on:click="onActions('fetch_paid_invoices_from_accounting')"
                        v-ripple.400
                        size="lg"
                        class="text-white w-full lg:w-[65%] rounded bg-[#4fcfc0] py-[10px] hover:drop-shadow"

                >
          <span

              v-text="$t('git.invoice.paid_git_invoice.fetch_paid_invoices_from_accounting')"
          />
                </button>
              </div>


              <div class="py-[5px]">
                <button v-b-tooltip="$t('git.invoice.paid_git_invoice.update_payment_status_for_all_paid_invoice_details')"
                        v-on:click="onActions('update_payment_status_for_all_paid_invoice')"
                        v-ripple.400
                        size="lg"
                        class="text-white w-full lg:w-[65%] rounded bg-[#4fcfc0] py-[10px] hover:drop-shadow"

                >
          <span

              v-text="$t('git.invoice.paid_git_invoice.update_payment_status_for_all_paid_invoice')"
          />
                </button>
              </div>
              <div class="py-[5px]">
                <button v-b-tooltip="$t('git.invoice.paid_git_invoice.update_payment_status_for_selected_invoice_details')"
                        v-on:click="onActions('update_payment_status_for_selected_invoice')"
                        v-ripple.400
                        size="lg"
                        class="text-white w-full lg:w-[65%] rounded bg-[#4fcfc0] py-[10px] hover:drop-shadow"

                >
          <span

              v-text="$t('git.invoice.paid_git_invoice.update_payment_status_for_selected_invoice')"
          />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal
        id="git-paid-actions"
        :hideFooter="true"
        :title="$t('confirmation')"
    >
      <b-card-text
          class="d-flex flex-column text-center align-items-center justify-content-center"
          style="gap: 1rem"
      >
        <img
            src="@/assets/duplioAsset/icons/warningCircle.png"
            width="70px"
            height="70px"
        />
        <p class="mb-0 pb-0">
          {{ $t(`${actionText[actionType]}`) }}
        </p>
      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            :disabled="loading"
            @click="$bvModal.hide('git-paid-actions')"
        >
          <span v-text=" $t('Message.cancel')"/>
        </b-button>
        <b-button
            v-on:click="submitAction"
            :disabled="loading"
            variant="primary"
            size="md"
        >
              <span
                  v-text="loading ? $t('Message.loading') : $t('confirm')"
              />
        </b-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import paymentList from "@core/components/golfrelated/git/invoices/payments/paymentList";
import {mapGetters} from "vuex"

export default {
  name: "PaidGitInvoice",
  components: {
    paymentList
  },
  data: function () {
    return {
      actionText: {
        "update_payment_status_for_all_paid_invoice": "git.invoice.paid_git_invoice.update_payment_status_for_all_paid_invoice",
        "update_payment_status_for_selected_invoice": "git.invoice.paid_git_invoice.update_payment_status_for_selected_invoice",
        "fetch_paid_invoices_from_accounting": "git.invoice.paid_git_invoice.fetch_paid_invoices_from_accounting",
      },
      loading: false,
      actionType: 'update_payment_status_for_all_paid_invoice'
    }
  },
  methods: {
    onActions: function (actionType) {
      if (actionType === 'update_payment_status_for_selected_invoice') {
        if (this.GET_SELECTED_PAID_INVOICE.length === 0) {
          this.popupMsg(
              this.$t("Message.Failed"),
              this.$t('git.invoice.no_invoice_selected'),
              "AlertTriangleIcon",
              "danger"
          );
          return false
        }
      }
      this.actionType = actionType
      this.$bvModal.show("git-paid-actions");
    },
    submitAction: function () {
      let payload = {}
      if (this.actionType === 'update_payment_status_for_selected_invoice'  || this.actionType === 'update_payment_status_for_all_paid_invoice') {
        let mode = {method: 'post', URL: 'update_invoice_payment_statuses/'}
        if (this.actionType === 'update_payment_status_for_selected_invoice') {
          payload['invoice_uuids'] = []
          this.GET_SELECTED_PAID_INVOICE.map(item => {
            payload['invoice_uuids'].push(item.uuid)
          })
        } 
        this.loading = true
        this.$useJwt.getGitAction(mode, payload).then(resp => {
          const {data} = resp;

          this.popupMsg(
              this.$t('Message.Success'),
              data[this.locale_message],
              'CheckIcon',
              'success'
          );
          this.loading = false

          this.$bvModal.hide("git-paid-actions");
          window.Bus.$emit('reload-paid-invoice')
        }).catch(e => {
          this.loading = false
          this.$bvModal.hide("git-paid-actions");
          this.popupMsg(
              this.$t("Message.Failed"),
              e.response.data[this.locale_message],
              "AlertTriangleIcon",
              "danger"
          );
        })
      } else if (this.actionType === 'fetch_paid_invoices_from_accounting') {
        // this.$useJwt.customSync({URL: '/invoices?filter=fullypaid', method: 'get'})
        this.$useJwt.customSync({URL: '/invoices', method: 'get'})
          .then(resp => {
            const {data} = resp;
            this.popupMsg(
              this.$t('Message.Success'),
              data[this.locale_message],
              'CheckIcon',
              'success'
            );
            this.loading = false

            this.$bvModal.hide("git-paid-actions");
            window.Bus.$emit('reload-paid-invoice')
          }).catch(e => {
            this.loading = false
            this.$bvModal.hide("git-paid-actions");
            this.popupMsg(
                this.$t("Message.Failed"),
                e.response.data[this.locale_message],
                "AlertTriangleIcon",
                "danger"
            );
          })
      }
    }
  },
  computed: {
    locale_message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    ...mapGetters({
      GET_SELECTED_PAID_INVOICE: 'git/GET_SELECT_PAID_INVOICE',
      GET_LOCALE: 'appConfig/getLocale'
    })
  }
}
</script>

<style scoped>

</style>